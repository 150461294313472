import React from 'react'

import { Tooltip, TooltipProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { InfoOutlined, SvgIconComponent } from '../../icons'


const useStyles = makeAppStyles( theme => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 12,
    lineHeight: 1.6,
    padding: theme.spacing(1),
    boxShadow: theme.shadows['12'],
  },
  icon: {
    fontSize: 16,
    padding: 0,
  },
}) )


export type IconTooltipProps = {
  IconComponent?: SvgIconComponent
  className?: string
} & Omit<TooltipProps, 'children'>


export const SecondaryIconTooltip = ({
  IconComponent = InfoOutlined,
  ...props
}: IconTooltipProps): JSX.Element => {

  const classes = useStyles()

  return (
    <Tooltip
      {...props}
      classes={{
        tooltip: classes.tooltip,
      }}>
      <IconComponent
        className={classes.icon}
        color='inherit' />
    </Tooltip>
  )
}
