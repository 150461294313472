import { useMemo } from 'react'

import {  useLocationParams, useReportSeries } from '@percept/hooks'

import { get, some } from 'lodash-es'

import { coerceReportProvider } from '@percept/utils'

import { useLocation } from 'react-router'

import { makeSelectorHook } from '@percept/hooks/libv2'

import { getActiveOrgEnvironment, getActiveOrgUnit, getActiveOrgUnitHierarchy } from './selectors'

import { matchToSeriesId } from './utils'

import { providerChannelMap } from '@percept/constants'

import { OrgNavigation } from './typings'


export const useActiveOrgEnvironment = makeSelectorHook(
  getActiveOrgEnvironment
)


export const useActivePlatformUnit = makeSelectorHook(
  getActiveOrgUnit
)


export const useActivePlatformUnitHierarchy = makeSelectorHook(
  getActiveOrgUnitHierarchy
)


export const useOrgNavigation = (): OrgNavigation => {

  const activeOrgEnvironment = useActiveOrgEnvironment()

  // const org_unit_id = get(activeOrgEnvironment.activePlatformUnit, 'id', null)

  const location = useLocation()

  const { channel, provider, series_id } = useLocationParams()

  const structuralReportingSeries = matchToSeriesId(
    get(activeOrgEnvironment.activePlatformUnit, 'report_series'),
    series_id,
  )

  // const [report] = useReportSeriesReportStructure({
  //   org_unit_id,
  //   series_id,
  //   report_id,
  // })

  // const [report] = useClientReport({ report_id })

  // const canonicalSeriesId = series_id || report_id && get(report.data, 'series_id', null)

  const [reportSeries] = useReportSeries({
    series_id,
  })

  const activeSeries = reportSeries.data

  return useMemo(() => {

    const activeProvider = (
      provider && coerceReportProvider(provider)
      || structuralReportingSeries && coerceReportProvider(structuralReportingSeries.provider.slug)
    )

    return {
      ...activeOrgEnvironment,
      isInsightsReportView: location.pathname.includes('insights-report'),
      isInsightsView: /insights$/.test(location.pathname),
      isHealthVsPerformanceView: location.pathname.includes('health-vs-performance'),
      isPerformanceReportView: location.pathname.includes('performance-report'),
      isPerformanceView: location.pathname.endsWith('performance') || location.pathname.endsWith('media-quality-rate'),
      isChannelView: some(
        ['adform', 'facebook', 'google-ads', 'dv360', 'amazon-ads', 'tiktok'],
        p => location.pathname.includes(p)
      ),
      activeChannel: (
        channel
        || activeProvider && providerChannelMap[activeProvider]
        || null
      ),
      activeProvider,
      activeSeries,
    }
  }, [activeOrgEnvironment, location.pathname, channel, provider, structuralReportingSeries, activeSeries])
}
