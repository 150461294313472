import React, { useEffect, useState } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonPopoverProps,
  CheckboxGroup,
  Divider,
  ProviderLogo,
  RoundedPlainTextButton,
} from '@percept/mui'
import { ArrowDropDown } from '@percept/mui/icons'

import { PerformanceProvider, ReportProviderV2 } from '@percept/types'


type ProviderSelectOption = PerformanceProvider | ReportProviderV2

export type MultiProviderSelectProps = {
  value: ProviderSelectOption[]
  options: ProviderSelectOption[]
  onChange: (value: ProviderSelectOption[]) => void
  ButtonPopoverProps?: Partial<Omit<ButtonPopoverProps, 'children'>>
  providerLabel?: string
}


export const MultiProviderSelect = ({
  value,
  options,
  onChange,
  ButtonPopoverProps,
  providerLabel = 'Provider',
}: MultiProviderSelectProps): JSX.Element => {

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const areFiltersApplied = (
    value.sort().join() === localValue.sort().join()
  )

  const isEmptyValue = localValue.length === 0

  return (
    <ButtonPopover
      variant='contained'
      endIcon={<ArrowDropDown />}
      ButtonComponent={RoundedPlainTextButton}
      buttonContent={`Select ${providerLabel}s`}
      onClose={(): void => {
        setLocalValue(value)
      }}
      {...ButtonPopoverProps}>
      { ({ onClose }): JSX.Element => (
        <Box p={2}>
          <Box ml={0}>
            <CheckboxGroup
              name={`${providerLabel}s`}
              value={localValue}
              options={
                options.map( provider => ({
                  value: provider,
                  label: <ProviderLogo provider={provider} size={1.65} style={{position: 'relative', top: 3}} />,
                }))
              }
              onChange={setLocalValue} />
          </Box>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={0}>
            <Divider />
          </Box>
          <Box pt={2} textAlign='right'>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={areFiltersApplied || isEmptyValue}
              onClick={(): void => {
                onChange(localValue)
                onClose()
              }}>
              { isEmptyValue ? `Select A ${providerLabel}` : areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
