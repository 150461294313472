import { useMemo } from 'react'

import { CreativeWastageResponseWithMarket, OrgUnitProviderPotentialEfficiencyScores } from '@percept/hooks'

import {
  getDerivedCreativeXDatasetMapping,
  getDerivedPotentialEfficiencyDatum,
  getDerivedTotalWastageDatum,
  produceKeyedMapping,
} from './dataUtils'

import {
  DerivedDimension,
  MarketDatum,
  OptionalComparisonDateRanges,
  OverviewGranularity,
  OverviewTableRow,
} from './typings'

import { VodafoneMarket } from 'vodafoneMarkets'

import {
  useBrandInvestmentShareBreakdownByMarket,
  useMediaInvestmentReportsByMarket,
} from './globalDataHooks'

import { ComparisonMethod, aggregatePotentialEfficiencies, getComparisonFunction } from '@percept/utils'

import { PerformanceValue, PotentialEfficiencyDatum } from '@percept/types'


export type MarketOverviewDatasets = Record<DerivedDimension, MarketDatum | null>

export type MarketOverviewDatasetsHookProps = {
  vodafoneMarket: VodafoneMarket
  granularity: OverviewGranularity
  dateRanges: OptionalComparisonDateRanges
  comparisonMethod: ComparisonMethod
  primaryCreativeWastageByMarket: CreativeWastageResponseWithMarket[] | undefined
  comparisonCreativeWastageByMarket: CreativeWastageResponseWithMarket[] | undefined
  potentialEfficiencyRatiosByMarket: OrgUnitProviderPotentialEfficiencyScores[] | undefined
  secondaryPotentialEfficiencyRatiosByMarket: OrgUnitProviderPotentialEfficiencyScores[] | undefined
}

export const useMarketOverviewDatasets = ({
  vodafoneMarket,
  granularity,
  dateRanges,
  comparisonMethod,
  primaryCreativeWastageByMarket,
  comparisonCreativeWastageByMarket,
  potentialEfficiencyRatiosByMarket,
  secondaryPotentialEfficiencyRatiosByMarket,
}: MarketOverviewDatasetsHookProps): MarketOverviewDatasets => {
  return useMemo(() => {
    if(
      !(
        primaryCreativeWastageByMarket
        && comparisonCreativeWastageByMarket
        && potentialEfficiencyRatiosByMarket
      )
    ){
      return {
        creative_quality_score: null,
        creative_wastage: null,
        digital_wastage: null,
        total_wastage: null,
      }
    }

    const primaryCreativeWastageMapping = produceKeyedMapping(
      primaryCreativeWastageByMarket, 'market'
    )
    const comparisonCreativeWastageMapping = produceKeyedMapping(
      comparisonCreativeWastageByMarket, 'market'
    )

    const matchedPrimaryCreativeWastage = primaryCreativeWastageMapping[vodafoneMarket.creativeXMarket] || null
    const matchedComparisonCreativeWastage = comparisonCreativeWastageMapping[vodafoneMarket.creativeXMarket] || null

    const { creative_quality_score, creative_wastage } = getDerivedCreativeXDatasetMapping({
      primary: matchedPrimaryCreativeWastage,
      comparison: matchedComparisonCreativeWastage,
      comparisonMethod,
    })

    const combinedEfficiencies = [
      ...potentialEfficiencyRatiosByMarket,
      ...(secondaryPotentialEfficiencyRatiosByMarket || []),
    ]

    const potentialEffiencies = combinedEfficiencies.reduce( (acc, d) => {
      if( d.org_unit_id === vodafoneMarket.id ){
        return acc.concat(d.potential_efficiency_scores)
      }
      return acc
    }, [] as PotentialEfficiencyDatum[])

    const derivedPrimaryDigitalWastage = aggregatePotentialEfficiencies(
      potentialEffiencies,
      dateRanges.primary,
    )
    const derivedComparisonDigitalWastage = aggregatePotentialEfficiencies(
      potentialEffiencies,
      dateRanges.comparison,
    )
    const derivedDigitalWastage = getDerivedPotentialEfficiencyDatum({
      primary: derivedPrimaryDigitalWastage,
      comparison: derivedComparisonDigitalWastage,
      comparisonMethod,
    })

    const derivedTotalWastage = getDerivedTotalWastageDatum({
      primaryCreativeWastage: matchedPrimaryCreativeWastage,
      primaryPotentialEfficiency: derivedPrimaryDigitalWastage,
      secondaryCreativeWastage: matchedComparisonCreativeWastage,
      secondaryPotentialEfficiency: derivedComparisonDigitalWastage,
      comparisonMethod,
    })

    return {
      creative_quality_score: { granularity, ...creative_quality_score },
      creative_wastage: { granularity, ...creative_wastage },
      digital_wastage: { granularity, ...derivedDigitalWastage },
      total_wastage: { granularity, ...derivedTotalWastage },
    }
  }, [
    vodafoneMarket,
    granularity,
    dateRanges,
    comparisonMethod,
    primaryCreativeWastageByMarket,
    comparisonCreativeWastageByMarket,
    potentialEfficiencyRatiosByMarket,
    secondaryPotentialEfficiencyRatiosByMarket,
  ])
}


const makeNullTableRow = (label: string, target: PerformanceValue = null): OverviewTableRow => ({
  label,
  score: null,
  value: null,
  previous: null,
  delta: null,
  target,
})


export const useMarketBrandInvestmentShareRows = ({
  vodafoneMarket,
  referenceDate,
  comparisonMethod = 'ABSOLUTE',
}: {
  vodafoneMarket: VodafoneMarket
  referenceDate: Date | null
  comparisonMethod: ComparisonMethod
}): {
  isLoading: boolean
  data: OverviewTableRow[] | undefined
} => {
  const { data: derivedBrandShareData } = useBrandInvestmentShareBreakdownByMarket({
    referenceDate
  })

  const { data: derivedTotalBrandData } = useMediaInvestmentReportsByMarket({
    referenceDate
  })

  const comparisonFunction = getComparisonFunction(comparisonMethod)

  const isLoading = !derivedBrandShareData || !derivedTotalBrandData

  let data: OverviewTableRow[] | undefined = undefined

  if( derivedBrandShareData && derivedTotalBrandData ){
    const matchedTotalDatum = derivedTotalBrandData.brand_investment_share[vodafoneMarket.iso_code]
    const allMediaRow: OverviewTableRow = (
      matchedTotalDatum ?
        {
          label: 'All Media',
          score: matchedTotalDatum.current,
          value: matchedTotalDatum.value,
          previous: matchedTotalDatum.previous,
          delta: comparisonFunction(matchedTotalDatum.current, matchedTotalDatum.previous),
          target: matchedTotalDatum.target,
        } : makeNullTableRow('All Media', 60)
    )
    const segmentedRows: OverviewTableRow[] = (
      derivedBrandShareData[vodafoneMarket.iso_code]
      || [makeNullTableRow('TV', null), makeNullTableRow('Digital', null)]
    )
    data = [
      allMediaRow,
      ...segmentedRows,
    ]
  }

  return {
    isLoading,
    data,
  }
}
