import React, { useEffect, useState } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  DateRangeValue,
  RoundedPlainTextButtonMenu,
  Typography,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { DateRangePopover } from './DateRangePopover'

import { MediaQualityRateTable } from './MediaQualityRateTable'

import { useMediaQualityRate } from './hook'

import { useUserPlatformUnits, usePlatformUnitProviderInfo } from '@percept/hooks'

import {
  DateRangePreset,
  areOrgsEqual,
  dateRangeCalculators,
  flattenNestedItems,
  getMaxDate,
} from './lib'

import { isPlatformUnitContainer } from '@percept/utils'

import { PlatformUnit } from '@percept/types'


export const MediaQualityRate = (): JSX.Element => {

  const [platformUnits] = useUserPlatformUnits()

  const platformUnitOptions = flattenNestedItems(
    platformUnits.data || []
  ).filter( u => !isPlatformUnitContainer(u) )

  const [platformUnit, setPlatformUnit] = useState<PlatformUnit | null>(platformUnitOptions[0] || null)

  const [referenceDates] = usePlatformUnitProviderInfo({
    org_unit_id: platformUnit && platformUnit.id
  })

  const maxDate = referenceDates.data && getMaxDate(referenceDates.data)

  const maxDateRef = maxDate && maxDate.getTime()

  const [dateRangePreset, setDateRangePreset] = useState<DateRangePreset | 'custom'>('current-financial-year')

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(() => (
    maxDate === null ?
      [null, null] :
      dateRangeCalculators['current-financial-year'](maxDate)
  ))

  const [expandedViewEnabled, setExpandedViewEnabled] = useState(false)

  useEffect(() => {
    if( maxDateRef && dateRange[0] === null && dateRangePreset !== 'custom' ){
      setDateRange(
        dateRangeCalculators[dateRangePreset](new Date(maxDateRef))
      )
    }
  }, [maxDateRef, dateRangePreset, dateRange])

  useEffect(() => {
    if( platformUnit === null && platformUnits.data && platformUnits.data.length ){
      const filteredPlatformUnits = flattenNestedItems(
        platformUnits.data || []
      ).filter( u => !isPlatformUnitContainer(u) )
      const defaultOption = filteredPlatformUnits[0]
      setPlatformUnit(defaultOption)
    }
  }, [platformUnit, platformUnits.data])

  const queryResult = useMediaQualityRate({
    platformUnitId: platformUnit && platformUnit.id,
    start: dateRange && dateRange[0] || null,
    end: dateRange && dateRange[1] || null,
    groupBy: 'PROVIDER',
  })

  const queryResultByPlacement = useMediaQualityRate({
    platformUnitId: platformUnit && platformUnit.id,
    start: dateRange && dateRange[0] || null,
    end: dateRange && dateRange[1] || null,
    groupBy: 'PLACEMENT',
    enabled: expandedViewEnabled,
  })

  const showLoader = (
    queryResult.isLoading
    || (!queryResult.data && !queryResult.isError)
    || (expandedViewEnabled && queryResultByPlacement.isLoading)
  )

  return (
    <Box pt={1}>

      <Box p={3}>
        <Box mb={3} display='flex' alignItems='center'>
          <Typography variant='h4'>Media Quality Rate</Typography>
          <Box mx={3}>
            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                color: 'secondary',
                size: 'small',
                endIcon: <ArrowDropDown />,
              }}
              value={platformUnit}
              label={platformUnit && platformUnit.name}
              isEqual={areOrgsEqual}
              options={
                platformUnitOptions.map( u => ({
                  label: u.name,
                  value: u,
                }))
              }
              onChange={(e, value) => {
                setPlatformUnit(value)
              }} />
          </Box>

          { dateRange[0] && dateRange[1] && (
            <DateRangePopover
              color='primary'
              dateRangePreset={dateRangePreset}
              setDateRangePreset={setDateRangePreset}
              value={dateRange as DateRangeValue}
              onChange={setDateRange}
              maxDate={maxDate} />
          )}
        </Box>

        <Card>
          <MediaQualityRateTable
            rowsByProvider={queryResult.data}
            rowsByPlacement={queryResultByPlacement.data}
            onRowExpand={(): void => {
              setExpandedViewEnabled(true)
            }} />
        </Card>
      </Box>

      { showLoader && <BackdropLoader /> }
    </Box>
  )
}
