import React, { Fragment } from 'react'

import { Link, useLocation } from 'react-router-dom'

import { AppTheme, makeAppStyles, useMediaQuery } from '@percept/mui'

import { ChevronLeftThin, ChevronRightThin } from '@percept/mui/icons'

import { findLastIndex } from 'lodash-es'


const useStyles = makeAppStyles( theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    paddingLeft: theme.spacing(1),
    zIndex: 100,
    top: 52,
    height: 50,
    borderBottom: '1px solid #BEBEBE',
    backgroundColor: '#FFF',
  },
  breadcrumb: {
    fontSize: 14,
    lineHeight: '18px',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    margin: theme.spacing(0, 2),
  },
  activeBreadcrumb: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 700,
    margin: theme.spacing(0, 2),
  },
  separatorRight: {
    strokeWidth: 1.5,
    fontSize: 16,
    // Correction for icon graphic padding
    marginRight: theme.spacing(-0.5),
  },
  separatorLeft: {
    strokeWidth: 1.5,
    fontSize: 16,
    marginLeft: theme.spacing(2),
    // Correction for icon graphic padding
    position: 'relative',
    top: 2,
  },
}))


export type Breadcrumb = {
  name: string
  path: string
}

export type BreadcrumbsProps = {
  hierarchy: Breadcrumb[]
}

const homeBreadCrumb: Breadcrumb = {
  name: 'Home',
  path: '/wizard/home',
}

export const Breadcrumbs = ({
  hierarchy
}: BreadcrumbsProps): JSX.Element | null => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('xs'))
  const { pathname } = useLocation()
  const activeIndex = findLastIndex(hierarchy, crumb => pathname.startsWith(crumb.path))
  if( isMobile ){
    const activeCrumb = hierarchy[activeIndex]
    const prevCrumb = hierarchy[activeIndex - 1] || homeBreadCrumb
    return (
      <div className={classes.root}>
        <Link to={prevCrumb.path}>
          <ChevronLeftThin className={classes.separatorLeft} />
        </Link>
        <Link to={activeCrumb.path} className={classes.activeBreadcrumb}>
          { activeCrumb.name }
        </Link>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Link className={classes.breadcrumb} to='/wizard/home'>
        Home
      </Link>
      { !!hierarchy.length && hierarchy.map( (crumb, i) => {
        const className = i === activeIndex ? classes.activeBreadcrumb : classes.breadcrumb
        return (
          <Fragment
            key={crumb.path}>
            <ChevronRightThin className={classes.separatorRight} />
            <Link
              className={className}
              to={crumb.path}>
              { crumb.name }
            </Link>
          </Fragment>
        )
      })}
    </div>
  )
}
