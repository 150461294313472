import { vodafoneMarkets } from 'vodafoneMarkets'

import { produceKeyedMapping } from './dataUtils'

import { singleQuarterlyMarketValues } from './singleQuarterlyMarketValues'
import { singleYearlyMarketValues } from './singleYearlyMarketValues'

import { OverviewEntity, SingleMarketOverviewDatum } from './typings'


export const yearlyByMarket = produceKeyedMapping(singleYearlyMarketValues, 'market_name') as Record<OverviewEntity, SingleMarketOverviewDatum>

export const quarterlyByMarket = produceKeyedMapping(singleQuarterlyMarketValues, 'market_name') as Record<OverviewEntity, SingleMarketOverviewDatum>

export const vodafoneMarketsByIsoCode = produceKeyedMapping(vodafoneMarkets, 'iso_code')
