import { CreativeQualityScoreTimeseriesDatum } from '@percept/hooks'

import { avg, mapValues } from '@percept/utils'

import { produceKeyedMapping } from 'components/Overview/dataUtils'

import { vodafoneMarkets } from 'vodafoneMarkets'


const vodafoneMarketsByCreativeXMarket = produceKeyedMapping(vodafoneMarkets, 'creativeXMarket')


type GroupedCreativeXQualityScoreData = Record<string, Record<string, number | null>>

type AveragedCreativeXQualityScoreData = Record<string, number | null>


export const groupCreativeXTimeseries = (data: CreativeQualityScoreTimeseriesDatum[]): GroupedCreativeXQualityScoreData => {
  return data.reduce( (acc, d) => {
    acc[d.month_start] = acc[d.month_start] || {}
    const market = vodafoneMarketsByCreativeXMarket[d.market]
    acc[d.month_start][market.iso_code] = d.creative_quality_score
    return acc
  }, {} as GroupedCreativeXQualityScoreData)
}


export type ParsedCreativeXQualityScoreData = {
  grouped: GroupedCreativeXQualityScoreData
  averaged: AveragedCreativeXQualityScoreData
}

export const parseCreativeQualityScoreData = (data: CreativeQualityScoreTimeseriesDatum[]): ParsedCreativeXQualityScoreData => {
  const grouped = groupCreativeXTimeseries(data)
  const averaged: AveragedCreativeXQualityScoreData = mapValues(grouped, group => {
    const values = Object.values(group).filter( v => v !== null ) as number[]
    if( !values.length ){
      return null
    }
    return avg(values)
  })
  return {
    grouped,
    averaged
  }
}
