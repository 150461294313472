import React, { useContext } from 'react'

import { AppTheme, Card, CardContent, PercentageDonut, useAppTheme, vodafonePalette } from '@percept/mui'
import { AsteriskIcon } from '@percept/mui/icons'

import MediaInvestmentApi, { SpendingType } from 'dashboard/src/api/services/Api'
import CompetitiveApi from 'dashboard/src/api/services/CompetitiveReports'

import { UserPrivilegeContext } from '@percept/app-components'

import { useMediaInvestmentReportsByMarket } from 'components/Overview/globalDataHooks'

import { CarouselItem } from 'components/Carousel'

import { ChartData, financialYearFormatter, percentageFormatter, ResponsiveHistogram } from '@percept/mui/charts'

import { ParentSize } from '@visx/responsive'

import { get, maxBy, minBy, round } from 'lodash-es'

import { endOfMonth, format, subYears } from 'date-fns'

import { getAbsoluteDelta, getFinancialYearStart, userHasGlobalPrivileges, userHasOrgPrivileges } from '@percept/utils'

import { produceKeyedMapping } from 'components/Overview/dataUtils'

import { GlobalPrivilege, OrgPrivilege } from '@percept/types'

import { LiveCarouselHookProps } from './typings'

import { ListItem } from 'dashboard/src/components/ReportingDashboard/types'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'


const IGNORE_ISO_CODES: string[] = [
  'ES',  // ES are excluded from banner data and availability checks
]
const IGNORE_VODAFONE_MARKETS: VodafoneMarket[] = (
  vodafoneMarkets.filter( m => IGNORE_ISO_CODES.includes(m.iso_code) )
)
const IGNORED_VODAFONE_MARKETS_BY_ORG_UNIT_ID: Record<string, VodafoneMarket> = (
  produceKeyedMapping(IGNORE_VODAFONE_MARKETS, 'id')
)
const IGNORED_VODAFONE_MARKETS_BY_NAME: Record<string, VodafoneMarket> = (
  produceKeyedMapping(IGNORE_VODAFONE_MARKETS, 'name')
)

export const useMediaInvestmentFYToDate = ({
  spendingType,
  enabled,
}: {
  spendingType: SpendingType
  enabled: boolean
}): [Date, Date] | null => {
  const submissionTracker = MediaInvestmentApi.useSubmissionTracker({
    spendingType,
    enabled,
  })

  if( !submissionTracker.data ){
    return null
  }

  const latestAvailableDatesByMarket = submissionTracker.data.reduce( (acc, { org_unit_id, agency_id, department_id, status, report_date }) => {
    if( IGNORED_VODAFONE_MARKETS_BY_ORG_UNIT_ID[org_unit_id] ){
      return acc
    }
    if( status === 'updated'){
      department_id = department_id || 'DEFAULT'
      if( !acc[org_unit_id] ){
        acc[org_unit_id] = {}
      }
      if( !acc[org_unit_id][agency_id] ){
        acc[org_unit_id][agency_id] = {}
      }
      if( !acc[org_unit_id][agency_id][department_id] ){
        acc[org_unit_id][agency_id][department_id] = []
      }
      acc[org_unit_id][agency_id][department_id].push(new Date(report_date))
    }
    return acc
  }, {} as Record<string, Record<string, Record<string, Date[]>>>)

  const endDates: Date[] = []
  Object.values(latestAvailableDatesByMarket).forEach( orgMapping => {
    Object.values(orgMapping).forEach( agencyMapping => {
      Object.values(agencyMapping).forEach( dates => {
        const latestDate = maxBy(dates, d => d.getTime())
        if( latestDate ){
          endDates.push(latestDate)
        }
      })
    })
  })

  const endDate = minBy(endDates, d => d.getTime())
  if( endDate ){
    const dateRange: [Date, Date] = [getFinancialYearStart(endDate), endDate]
    return dateRange
  }

  return null
}


const deriveSOSData = ({
  sosReport,
  appTheme,
}: {
  sosReport: ListItem[]
  appTheme: AppTheme
}): {
  chartData: ChartData
  changeDeltas: (number | null)[]
} => {
  const colorScale = appTheme.chart.getOrdinalColourScale(vodafoneMarkets.map( m => m.name))
  return sosReport.reduce( (acc, listItem) => {
    if( listItem.isTotalRow ){
      return acc
    }
    if( IGNORED_VODAFONE_MARKETS_BY_NAME[listItem.row_group] ){
      return acc
    }
    const current = get(listItem.costs[listItem.costs.length - 1], 'percent', null)
    const baseline = get(listItem.costs[listItem.costs.length - 2], 'percent', null)
    let value: number | null = null
    let delta: number | null = null
    if( current !== null ){
      value = round(Number(current), 2)
    }
    if( (current && baseline) ){
      const absoluteDelta = getAbsoluteDelta(Number(current), Number(baseline))
      delta = absoluteDelta === null ? null : round(absoluteDelta, 2)
    }
    acc.chartData.push({
      label: listItem.row_group,
      value,
      color: colorScale(listItem.row_group)
    })
    acc.changeDeltas.push(delta)
    return acc
  }, { chartData: [] as ChartData, changeDeltas: [] as (number | null)[] })
}


const getDisplayDateRange = (dateRange: [Date, Date]): string => {
  const displayStartMonth = format(dateRange[0], 'MMMM')
  const displayEndMonth = format(dateRange[1], 'MMMM')

  const displayMonthRange = (
    displayStartMonth === displayEndMonth ?
      displayStartMonth :
      `${displayStartMonth} - ${displayEndMonth}`
  )

  return `${displayMonthRange} ${financialYearFormatter(dateRange[0])}`
}


export const useSOSMarketShareCarouselItem = (props: LiveCarouselHookProps): CarouselItem | null => {
  const privileges = useContext(UserPrivilegeContext)
  const requiredGlobalPrivileges: GlobalPrivilege[] = ['mediaInvestment.viewAny']
  const requiredOrgPrivileges: OrgPrivilege[] = ['mediaInvestment.competitive.view']
  const hasRequiredPrivileges = (
    userHasGlobalPrivileges(
      requiredGlobalPrivileges,
      privileges.global_privileges
    )
    && userHasOrgPrivileges(
      requiredOrgPrivileges,
      VODAFONE_GLOBAL_ID,
      privileges.org_privileges
    )
  )

  const dateRange = useMediaInvestmentFYToDate({
    spendingType: 'sos_spend',
    enabled: hasRequiredPrivileges,
  })

  const startMonth = dateRange ? format(dateRange[0], 'MMM') : undefined
  const endMonth = dateRange ? format(dateRange[1], 'MMM') : undefined

  const previousYear = dateRange && subYears(dateRange[0], 1)

  const {
    data: sosReport,
    isError,
  } = CompetitiveApi.useSOSSpendingByMarket({
    period: '',
    report_dates: [],
    start_month: startMonth ? [startMonth] : undefined,
    end_month: endMonth ? [endMonth] : undefined,
    startYear: previousYear ? previousYear.getFullYear() : undefined,
    enabled: hasRequiredPrivileges,
  })

  const appTheme = useAppTheme()

  if( !hasRequiredPrivileges || isError ){
    return null
  }

  if( !dateRange || !sosReport ){
    return {
      ...props,
      title: '',
      loading: true,
    }
  }

  const { chartData, changeDeltas } = deriveSOSData({ sosReport, appTheme })

  const totalMarkets = chartData.length
  const marketsWithIncreasedShare = changeDeltas.filter( d => d !== null && d > 0 ).length

  const displayDate = getDisplayDateRange(dateRange)
  const previousDisplayDate = getDisplayDateRange([
    subYears(dateRange[0], 1), subYears(dateRange[1], 1)
  ])

  const defaultOrgUnitId = get(privileges.user, 'default_org_unit_id', null)
  const href = defaultOrgUnitId && `/reporting/${defaultOrgUnitId}/competitive-investment`

  return {
    ...props,
    href,
    title: (
      <>
        {marketsWithIncreasedShare} out of {totalMarkets} markets increased SOS
        <span style={{color: vodafonePalette.red}}>*</span>
      </>
    ),
    footnote: (
      <span style={{display: 'flex', alignItems: 'center'}}>
        <AsteriskIcon style={{fontSize: 14}} color='primary' />
        {displayDate} compared to {previousDisplayDate}
      </span>
    ),
    infoGraphicContent: (
      <Card
        style={{
          width: '100%',
          height: 'calc(100% - 100px)',
        }}>
        <CardContent style={{height: '100%'}}>
          <ResponsiveHistogram
            animate
            animateInitial
            data={chartData}
            axisText
            grid
            yTickFormatter={percentageFormatter} />
        </CardContent>
      </Card>
    ),
  }
}


export const useBrandShareCarouselItem = (props: LiveCarouselHookProps): CarouselItem | null => {
  const privileges = useContext(UserPrivilegeContext)
  const requiredGlobalPrivileges: GlobalPrivilege[] = ['mediaInvestment.viewAny']
  const requiredOrgPrivileges: OrgPrivilege[] = ['mediaInvestment.investment.digital.view']
  const hasRequiredPrivileges = (
    userHasGlobalPrivileges(
      requiredGlobalPrivileges,
      privileges.global_privileges
    )
    && userHasOrgPrivileges(
      requiredOrgPrivileges,
      VODAFONE_GLOBAL_ID,
      privileges.org_privileges
    )
  )

  const dateRange = useMediaInvestmentFYToDate({
    spendingType: 'primary_spend',
    enabled: hasRequiredPrivileges,
  })

  const referenceDate = dateRange && endOfMonth(dateRange[1])

  const {
    data: brandInvestmentByMarket,
    isError,
  } = useMediaInvestmentReportsByMarket({
    referenceDate,
    comparisonMethod: 'ABSOLUTE',
    includeGroups: true,
    enabled: hasRequiredPrivileges,
  })

  if( !hasRequiredPrivileges || isError ){
    return null
  }

  if( !dateRange || !brandInvestmentByMarket ){
    return {
      ...props,
      title: '',
      loading: true,
    }
  }

  const totalDatum = get(brandInvestmentByMarket.brand_investment_share, 'All total', null)
  const currentTotal = Number(get(totalDatum, 'current', null))
  const previousTotal = Number(get(totalDatum, 'previous', null))
  const currentDelta = currentTotal - previousTotal

  const delta = round(currentDelta)
  const displayDelta = Math.abs(delta)

  const displayDate = getDisplayDateRange(dateRange)
  const previousDisplayDate = getDisplayDateRange([
    subYears(dateRange[0], 1), subYears(dateRange[1], 1)
  ])

  const changeStatus: 'INCREASE' | 'DECREASE' | 'STATIC' = (
    delta > 0 ?
      'INCREASE' :
      delta < 0 ?
        'DECREASE':
        'STATIC'
  )

  const deltaSuffix = displayDelta === 1 ? 'pt' : 'pts'

  const changeDescription = (
    changeStatus === 'INCREASE' ?
      `increased by ${displayDelta}${deltaSuffix} YOY` :
      changeStatus === 'DECREASE' ?
        `decreased by ${displayDelta}${deltaSuffix} YOY` :
        `stayed constant at ${percentageFormatter(currentTotal)}`
  )

  const defaultOrgUnitId = get(privileges.user, 'default_org_unit_id', null)
  const href = defaultOrgUnitId && `/reporting/${defaultOrgUnitId}`

  return {
    ...props,
    href,
    title: (
      <>
        Brand Share {changeDescription}
        <span style={{color: vodafonePalette.red}}>*</span>
      </>
    ),
    description: (
      <>
        To improve brand strength we need to reach a minimum of 60% brand investment.
      </>
    ),
    footnote: (
      <span style={{display: 'flex', alignItems: 'center'}}>
        <AsteriskIcon style={{fontSize: 14}} color='primary' />
        {displayDate} compared to {previousDisplayDate}
      </span>
    ),
    infoGraphicContent: (
      <div style={{width: '100%', height: 'calc(100% - 200px)'}}>
        <ParentSize>
          { ({ width, height }): JSX.Element => (
            <PercentageDonut
              animate
              animateInitial
              value={currentTotal}
              trackColor='rgba(255,255,255,0.15)'
              fontSize={80}
              width={width}
              height={height}
              color={vodafonePalette.red} />
          )}
        </ParentSize>
      </div>
    )
  }

}
