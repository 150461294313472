import React, { useContext, useState } from 'react'

import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import {
  Box,
  BoxProps,
  Divider,
  IconButtonPopover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeAppStyles,
  PerceptAppBar,
  Typography,
} from '@percept/mui'
import { CloudDownload, Description, Group, Settings } from '@percept/mui/icons'

import { UserPopover } from '@percept/app-components'

import { Footer } from 'components/Footer'
import { Breadcrumbs } from 'components/Links'

import { ExCoReportDownloadTrigger } from 'components/ExCoReport'

import { useNavigation } from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { isAdminUser, userHasGlobalPrivileges, userHasOrgPrivileges } from '@percept/utils'

import { GlobalPrivilege } from '@percept/types'

import shortLogo from '../images/logos/vodafone-logo-short.svg'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'
import { WizardNewsAdmin } from 'components/News'


const flexProps: BoxProps = {
  display: 'flex',
  alignItems: 'center',
}

const useStyles = makeAppStyles( theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100vw',
  },
  appBar: {
    color: 'black',
    backgroundColor: 'white',
  },
  logoAnchor: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  logo: {
    width: 28,
    height: 28,
  },
  navLink: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: theme.spacing(5),
  },
  navLinkActive: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textUnderlineOffset: '5px',
    marginRight: theme.spacing(5),
  },
  spacedRight: {
    marginRight: theme.spacing(3),
  },
  managementMenuItem: {
    fontSize: 14,
    fontWeight: 500,
  },
}) )


type NavLink = {
  path: string
  name: string
  globalPrivileges?: GlobalPrivilege[]
  dummy?: boolean
}

const defaultNavLinks: NavLink[] = [
  {
    path: '/wizard/overview',
    globalPrivileges: ['vfMicrosites.overview.view', 'vfMediaWizardOverview.viewAny'],
    name: 'Overview'
  },
  { path: '/wizard/strategy', name: 'Strategy' },
  { path: '/wizard/optimisation', name: 'Optimisation' },
  { path: '/wizard/reporting', name: 'Reporting' },
  { path: '/wizard/news', name: 'News' },
]

type DefaultMediaWizardLayoutProps = React.PropsWithChildren<{
  navLinks?: NavLink[]
  breadcrumbs?: NavLink[]
}>

export const DefaultMediaWizardLayout = ({
  children,
  navLinks,
  breadcrumbs,
}: DefaultMediaWizardLayoutProps): JSX.Element => {

  const privileges = useContext(UserPrivilegeContext)

  const canManageTenantUsers = (
    isAdminUser(privileges.user)
    || userHasGlobalPrivileges(['tenantManagement.viewAny'], privileges.global_privileges)
  )

  const canManageWizardNews = userHasOrgPrivileges(
    ['vfMediaWizard.news.edit'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges,
  )

  const [isManagingNews, setIsManagingNews] = useState(false)

  const canViewExCo = userHasOrgPrivileges(
    ['vfMediaWizard.exco.export'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges,
  )

  navLinks = (navLinks || defaultNavLinks).filter( link => (
    !link.globalPrivileges ?
      true :
      userHasGlobalPrivileges(link.globalPrivileges, privileges.global_privileges)
  ))

  const location = useLocation()

  const navigate = useNavigation()

  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <PerceptAppBar
        position='fixed'
        className={classes.appBar}
        color='default'
        startIcon={
          <Box ml={0.5} {...flexProps}>
            <Link to='/wizard/home'>
              <span className={classes.logoAnchor}>
                <img className={classes.logo} src={shortLogo} />
              </span>
            </Link>
          </Box>
        }>
        { !!navLinks.length && (
          <Box ml={4} mr={2}>
            { navLinks.map( ({ name, path, dummy = false }) => (
              dummy ? (
                <span className={classes.navLink}>{ name }</span>
              ) : (
                <Link
                  key={name}
                  className={
                    location.pathname.startsWith(path) ?
                      classes.navLinkActive :
                      classes.navLink
                  }
                  to={path}>
                  { name }
                </Link>
              )
            ))}
          </Box>
        )}
        <Box {...flexProps} marginLeft='auto'>
          { (canManageTenantUsers || canViewExCo || canManageWizardNews) && (
            <IconButtonPopover
              className={classes.spacedRight}
              IconComponent={Settings}>
              <Box minWidth={300}>
                <Box p={2} display='flex' alignItems='center'>
                  <Settings className={classes.spacedRight} />
                  <Typography variant='h5'>Admin</Typography>
                </Box>
                <Divider />
                <List>
                  { canManageTenantUsers && (
                    <ListItem button onClick={(): void => navigate('/wizard/user-management')}>
                      <ListItemIcon>
                        <Group />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{className: classes.managementMenuItem}}
                        primary='User Management' />
                    </ListItem>
                  )}
                  { canViewExCo && (
                    <ExCoReportDownloadTrigger
                      trigger={({ onClick }): JSX.Element => (
                        <ListItem button onClick={onClick}>
                          <ListItemIcon>
                            <CloudDownload />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{className: classes.managementMenuItem}}
                            primary='Download ExCo Report' />
                        </ListItem>
                      )} />
                  )}
                  { canManageWizardNews && (
                    <>
                      <ListItem button onClick={(): void => setIsManagingNews(true)}>
                        <ListItemIcon>
                          <Description />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{className: classes.managementMenuItem}}
                          primary='Media Wizard News Admin' />
                      </ListItem>
                      { canManageWizardNews && isManagingNews && (
                        <WizardNewsAdmin
                          onClose={(): void => {
                            setIsManagingNews(false)
                          }} />
                      )}
                    </>
                  )}
                </List>
              </Box>
            </IconButtonPopover>
          )}
          <UserPopover />
        </Box>
      </PerceptAppBar>
      <Box
        pt={breadcrumbs && breadcrumbs.length ? '102px' : '52px'}
        pb={6}
        flexGrow={1}
        bgcolor='white'>
        { !!(breadcrumbs && breadcrumbs.length) && (
          <Breadcrumbs
            hierarchy={breadcrumbs} />
        )}
        { children }
      </Box>
      <Footer />
    </div>
  )
}
