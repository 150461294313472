
export const VODAFONE_GLOBAL_ID = '10143060198421'

export const isVodafoneGlobalUser = (defaultPlatformUnitId: string | null): boolean => (
  defaultPlatformUnitId === VODAFONE_GLOBAL_ID
)

export type VodafoneMarket = {
  name: string
  id: string
  iso_code: string
  ordinal_no: number
  creativeXMarket: string
  shareOfSpendTarget: number | null
}

export const vodafoneMarkets: VodafoneMarket[] = [
  {
    name: 'Germany',
    id: '12830040392735',
    iso_code: 'DE',
    ordinal_no: 1,
    creativeXMarket: 'Germany',
    shareOfSpendTarget: 29,
  },
  {
    name: 'United Kingdom',
    id: '31774511989840',
    iso_code: 'UK',
    ordinal_no: 2,
    creativeXMarket: 'United Kingdom',
    shareOfSpendTarget: 15,
  },
  {
    name: 'Italy',
    id: '24907052221496',
    iso_code: 'IT',
    ordinal_no: 3,
    creativeXMarket: 'Italy',
    shareOfSpendTarget: 22,
  },
  {
    name: 'Spain',
    id: '14851904635942',
    iso_code: 'ES',
    ordinal_no: 4,
    creativeXMarket: 'Spain',
    shareOfSpendTarget: 22,
  },
  {
    name: 'South Africa',
    id: '32230978094163',
    iso_code: 'ZA',
    ordinal_no: 5,
    creativeXMarket: 'South Africa',
    shareOfSpendTarget: 37,
  },
  {
    name: 'Turkey',
    id: '31268880254029',
    iso_code: 'TR',
    ordinal_no: 6,
    creativeXMarket: 'Turkey',
    shareOfSpendTarget: 30,
  },
  {
    name: 'Egypt',
    id: '13480333673507',
    iso_code: 'EG',
    ordinal_no: 7,
    creativeXMarket: 'Egypt',
    shareOfSpendTarget: 35,
  },
  {
    name: 'Ireland',
    id: '23753861891122',
    iso_code: 'IE',
    ordinal_no: 8,
    creativeXMarket: 'Ireland',
    shareOfSpendTarget: 26,
  },
  {
    name: 'Portugal',
    id: '27303811744836',
    iso_code: 'PT',
    ordinal_no: 9,
    creativeXMarket: 'Portugal',
    shareOfSpendTarget: 30,
  },
  {
    name: 'Greece',
    id: '21641224520748',
    iso_code: 'GR',
    ordinal_no: 10,
    creativeXMarket: 'Greece',
    shareOfSpendTarget: null,
  },
  {
    name: 'Romania',
    id: '30509216302154',
    iso_code: 'RO',
    ordinal_no: 11,
    creativeXMarket: 'Romania',
    shareOfSpendTarget: 35,
  },
  {
    name: 'Czech Republic',
    id: '10734373176347',
    iso_code: 'CZ',
    ordinal_no: 12,
    creativeXMarket: 'Czechia',
    shareOfSpendTarget: 35,
  },
  {
    name: 'Albania',
    id: '10336979649558',
    iso_code: 'AL',
    ordinal_no: 13,
    creativeXMarket: 'Albania',
    shareOfSpendTarget: 50,
  }
]


export const legacyMarkets: VodafoneMarket[] = [
  {
    name: 'Hungary',
    id: '22432882623535',
    iso_code: 'HU',
    ordinal_no: 14,
    creativeXMarket: '',
    shareOfSpendTarget: null,
  },
  {
    name: 'Netherlands',
    id: '6431964473713034534',
    iso_code: 'NL',
    ordinal_no: 15,
    creativeXMarket: '',
    shareOfSpendTarget: null,
  },
]

export const partnerMarkets: VodafoneMarket[] = [
  {
    name: 'Australia',
    id: '6431964309285576585',
    iso_code: 'AU',
    ordinal_no: 16,
    creativeXMarket: '',
    shareOfSpendTarget: null,
  },
  {
    name: 'Qatar',
    id: '6431964397345773869',
    iso_code: 'QA',
    ordinal_no: 17,
    creativeXMarket: '',
    shareOfSpendTarget: null,
  },
]
