import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  min,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'

import { getFinancialYearStart } from '@percept/utils'

import { PlatformUnit, ProviderReferenceDates } from '@percept/types'


export type DateRangePreset = (
  | 'current-financial-year'
  | 'current-year'
  | 'last-year'
  | 'last-financial-year'
  | 'last-quarter'
  | 'last-month'
  | 'last-week'
)

export const dateRangeCalculators: Record<DateRangePreset, (referenceDate: Date) => [Date, Date]> = {
  'current-financial-year': refDate => [
    getFinancialYearStart(refDate), refDate
  ],
  'current-year': refDate => [
    startOfYear(refDate), refDate,
  ],
  'last-financial-year': refDate => [
    getFinancialYearStart(subYears(refDate, 1)), subDays(getFinancialYearStart(refDate), 1)
  ],
  'last-year': refDate => [
    startOfYear(subYears(refDate, 1)), endOfYear(subYears(refDate, 1))
  ],
  'last-quarter': refDate => [
    startOfQuarter(subQuarters(refDate, 1)), endOfQuarter(subQuarters(refDate, 1))
  ],
  'last-month': refDate => [
    startOfMonth(subMonths(refDate, 1)), endOfMonth(subMonths(refDate, 1))
  ],
  'last-week': refDate => [
    startOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 }),
    endOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 })
  ],
}


export function flattenNestedItems<T extends { children?: T[] }>(items: T[]): T[] {
  let output: T[] = []
  for( const item of items ){
    output.push(item)
    if( item.children ){
      output = output.concat(flattenNestedItems(item.children))
    }
  }
  return output
}

export const areOrgsEqual = (a: PlatformUnit | null, b: PlatformUnit | null): boolean => !!(
  a && b && a.id === b.id
)

export const getMaxDate = (referenceDates: ProviderReferenceDates): Date | null => {
  const providers: (keyof ProviderReferenceDates)[] = [
    'adform',
    'adwords',
    'doubleverify_adform',
    'doubleverify_adwords',
    'doubleverify_facebook',
    'dv360',
    'facebook',
    'AMAZON_ADS',
  ]
  const dates: Date[] = []
  for( const provider of providers ){
    if( referenceDates[provider] && referenceDates[provider].reference_date ){
      dates.push(new Date(referenceDates[provider].reference_date))
    }
  }
  return min(dates) || null
}
