import React from 'react'

import {
  AppTheme,
  Box,
  CellRenderers,
  Money,
  SimpleTableProps,
} from '@percept/mui'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { percentageDeltaFormatter, percentageFormatter, percentagePointDeltaFormatter } from '@percept/mui/charts'

import { vodafoneMarkets } from 'vodafoneMarkets'

import { format, startOfQuarter, subMonths, subQuarters, subYears } from 'date-fns'

import { uniq } from 'lodash-es'

import { getFinancialYearStart, getAbsoluteDelta, ComparisonMethod, getComparisonFunction } from '@percept/utils'

import { produceKeyedMapping } from './dataUtils'

import {
  MarketOverviewDatum,
  OverviewDimension,
  OverviewGranularity,
  OverviewTableRow,
} from './typings'


export type TableCategory = 'Optimisation' | 'Reporting' | 'Strategy'

export type OverviewDimensionMetadata = {
  category: TableCategory
  label: string
  tooltipContent?: string | JSX.Element
  deltaLabel: string
  scoreLabel?: string
  valueLabel?: string
}

export type TableDefinition = {
  category: TableCategory
  label: string
  tooltipContent?: string | JSX.Element
  fixedGranularity?: OverviewGranularity
  scoreLabel?: string
  dimension: OverviewDimension
  columns: SimpleTableProps<OverviewTableRow>['columns']
}

export type ColumnLabelProps = {
  deltaLabel: string
  scoreLabel?: string
  rowLabel?: string
  valueLabel?: string
}

export type TableDefinitionProps = (
  Pick<TableDefinition, 'category' | 'dimension' | 'label' | 'tooltipContent'> &
  ColumnLabelProps
)

export type TableConfig = {
  category: TableCategory
  dimension: OverviewDimension
  title: string
  tooltipContent?: string | JSX.Element
  props: SimpleTableProps<OverviewTableRow>
}


const formatFinancialYear = (date: Date): string => {
  const fyStart = getFinancialYearStart(date)
  const startYY = format(fyStart, 'yy')
  return `FY${startYY}/${Number(startYY) + 1}`
}

const formatMonth = (date: Date): string => (
  format(date, 'MMM-yy')
)

const formatCalendarYear = (date: Date): string => (
  format(date, 'yyyy')
)

const formatCalendarQuarter = (date: Date): string => (
  format(date, 'QQQ yyyy')
)

export const formatFinancialQuarter = (date: Date): string => {
  const financialQuarter = format(
    subQuarters(startOfQuarter(date), 1),
    'QQQ'
  )
  return `${financialQuarter} ${formatFinancialYear(date)}`
}

const granularityDateFormatters: Record<OverviewGranularity, (referenceDate: Date) => string> = {
  month: formatMonth,
  quarter: formatFinancialQuarter,
  year: formatFinancialYear,
  year_on_year_month: formatMonth,
  year_on_year_quarter: formatFinancialQuarter,
}

export const getDateLabel = (granularity: OverviewGranularity, referenceDate: Date): string => (
  granularityDateFormatters[granularity](referenceDate)
)


export const deltaLabelMap: Record<OverviewGranularity, string> = {
  month: 'MoM Change',
  quarter: 'QoQ Change',
  year: 'YoY Change',
  year_on_year_month: 'YoY (Month) Change',
  year_on_year_quarter: 'YoY (Quarter) Change',
}

const deltaTooltipFormatters: Record<OverviewGranularity, (referenceDate: Date) => string> = {
  month: date => {
    const latest = formatMonth(date)
    const previous = formatMonth(subMonths(date, 1))
    return (
      `${deltaLabelMap.month} - % change of latest month vs. the previous month (${latest} vs. ${previous})`
    )
  },
  quarter: date => {
    const latest = formatFinancialQuarter(date)
    const previous = formatFinancialQuarter(subQuarters(date, 1))
    return (
      `${deltaLabelMap.quarter} - % change of latest fiscal quarter vs. the previous fiscal quarter `
      + `(${latest} vs. ${previous})`
    )
  },
  year: date => {
    const previousDate = subYears(date, 1)
    const latestFyStart = getFinancialYearStart(date)
    const previousFyStart = getFinancialYearStart(previousDate)
    const latest = `${
      uniq([format(latestFyStart, 'MMM'), format(date, 'MMM')]).join(' - ')
    } ${formatFinancialYear(date)}`
    const previous = `${
      uniq([format(previousFyStart, 'MMM'), format(previousDate, 'MMM')]).join(' - ')
    } ${formatFinancialYear(previousDate)}`
    return (
      `${deltaLabelMap.year} - % change of latest fiscal year to date vs. the same period from the previous fiscal year `
      + `(${latest} vs. ${previous})`
    )
  },
  year_on_year_month: date => {
    const latest = formatMonth(date)
    const previous = formatMonth(subYears(date, 1))
    return (
      `${deltaLabelMap.year_on_year_month} - % change of latest month vs. the same month from the previous year (${latest} vs. ${previous})`
    )
  },
  year_on_year_quarter: date => {
    const latest = formatFinancialQuarter(date)
    const previous = formatFinancialQuarter(subYears(date, 1))
    return (
      `${deltaLabelMap.year_on_year_quarter} - % change of latest fiscal quarter vs. the same fiscal quarter `
      + `from the previous fiscal year (${latest} vs. ${previous})`
    )
  }
}


export const getTooltipContent = (granularity: OverviewGranularity, referenceDate: Date): string => (
  deltaTooltipFormatters[granularity](referenceDate)
)

export const overviewDimensionMetadata: Record<OverviewDimension, OverviewDimensionMetadata> = {
  digital_wastage: {
    category: 'Optimisation',
    label: 'Digital Wastage',
    tooltipContent: '% of digital media investment that is not following best practices',
    deltaLabel: 'YoY Change',
  },
  media_quality_rate: {
    category: 'Optimisation',
    label: 'Media Quality Rate',
    tooltipContent: '% of digital ad impressions that are brand safe, fraud-free, in-geo and viewable',
    deltaLabel: 'MoM Change',
  },
  creative_quality_score: {
    category: 'Optimisation',
    label: 'Creative Quality Score',
    tooltipContent: '% of digital creatives that follow 100% of ad buying platforms\' guidelines',
    deltaLabel: 'QoQ Change',
  },
  share_of_spend: {
    category: 'Strategy',
    label: 'Share Of Spend',
    tooltipContent: 'Share of media investment vs a set of competitors',
    deltaLabel: 'YoY Change',
    scoreLabel: 'Share Of Spend',
  },
  brand_investment_share: {
    category: 'Strategy',
    label: 'Brand Investment Share',
    tooltipContent: 'Share of Vodafone media investment focusing on sheer Brand Purpose or Brand proposition messages',
    deltaLabel: 'YoY Change',
    scoreLabel: 'Brand Media Spend',
  },
  production_vs_media: {
    category: 'Strategy',
    label: 'Production vs Media FY22/23',
    tooltipContent: '(production costs + agency costs) / (media investment + sponsoring investment)',
    deltaLabel: 'YoY Change',
  },
  total_wastage: {
    category: 'Optimisation',
    label: 'Total Wastage',
    tooltipContent: '% of digital media investment that is not following best practices',
    deltaLabel: 'QoQ Change',
  },
  creative_wastage: {
    category: 'Optimisation',
    label: 'Creative Wastage',
    tooltipContent: '% of digital media investment that is not following best practices',
    deltaLabel: 'QoQ Change',
  },
}

export const makeOverviewTableColumns = ({
  deltaLabel,
  scoreLabel = 'Score',
  rowLabel = 'Market',
}: ColumnLabelProps): SimpleTableProps<OverviewTableRow>['columns'] => {
  return [
    {
      key: 'label',
      label: rowLabel,
      align: 'left',
    },
    {
      key: 'score',
      label: scoreLabel,
      align: 'right',
    },
    {
      key: 'delta',
      label: deltaLabel,
      align: 'right',
    },
    {
      key: 'target',
      label: 'Target',
      align: 'right',
    },
  ]
}

export const makeWastageTableColumns  = ({
  deltaLabel,
  valueLabel = 'Value',
  scoreLabel = 'Score',
  rowLabel = 'Market',
}: ColumnLabelProps & { valueLabel?: string | undefined}): SimpleTableProps<OverviewTableRow>['columns'] => {
  return [
    {
      key: 'label',
      label: rowLabel,
      align: 'left',
    },
    {
      key: 'score',
      label: scoreLabel,
      align: 'right',
    },
    {
      key: 'value',
      label: valueLabel,
      align: 'right',
    },
    {
      key: 'delta',
      label: deltaLabel,
      align: 'right',
    },
  ]
}


const marketsByIsoCode = produceKeyedMapping(vodafoneMarkets, 'iso_code')


const getTableDefinitions = (granularity: OverviewGranularity): TableDefinition[] => {
  const deltaLabel = deltaLabelMap[granularity]
  return [
    {
      category: 'Reporting',
      label: 'Share Of Spend', // static column
      tooltipContent: 'Share of media investment vs. a set of competitors',
      fixedGranularity: 'year',
      dimension: 'share_of_spend',
      columns: makeOverviewTableColumns({ deltaLabel: deltaLabelMap.year }), // static column
    },
    {
      category: 'Reporting',
      label: 'Brand Investment Share', // static column
      tooltipContent: 'Share of Vodafone media investment focusing on sheer Brand Purpose or Brand proposition messages',
      fixedGranularity: 'year',
      dimension: 'brand_investment_share',
      columns: makeOverviewTableColumns({
        deltaLabel: deltaLabelMap.year, // static column
        scoreLabel: 'Brand Media Spend',
      }),
    },
    {
      category: 'Reporting',
      label: 'Production vs Media', // static column
      tooltipContent: '(production costs + agency costs) / (media investment + sponsoring investment)',
      fixedGranularity: 'year',
      dimension: 'production_vs_media',
      columns: makeOverviewTableColumns({ deltaLabel: deltaLabelMap.year }), // static column
    },
    {
      category: 'Optimisation',
      label: 'Digital Wastage',
      dimension: 'digital_wastage',
      columns: makeWastageTableColumns({ deltaLabel }),
    },
    {
      category: 'Optimisation',
      label: 'Creative Wastage',
      dimension: 'creative_wastage',
      columns: makeWastageTableColumns({ deltaLabel }),
    },
    {
      category: 'Optimisation',
      label: 'Total Wastage',
      dimension: 'total_wastage',
      columns: makeWastageTableColumns({ deltaLabel }),
    },
    {
      category: 'Optimisation',
      label: 'Creative Quality Score',
      dimension: 'creative_quality_score',
      columns: makeOverviewTableColumns({ deltaLabel }),
    },
    // {
    //   category: 'Optimisation',
    //   label: 'Media Quality Rate',
    //   tooltipContent: '% of digital ad impressions that are brand safe, fraud-free, in-geo and viewable',
    //   dimension: 'media_quality_rate',
    //   columns: makeOverviewTableColumns({ deltaLabel: 'MoM Change' }),
    // },
  ]
}


export const makeTables = (
  data: MarketOverviewDatum[],
  granularity: OverviewGranularity,
  referenceDate: Date,
  comparisonMethod: ComparisonMethod = 'ABSOLUTE',
): TableConfig[] => {
  const comparisonFunction = getComparisonFunction(comparisonMethod)
  return getTableDefinitions(granularity).map(
    ({ category, label, tooltipContent, dimension, fixedGranularity, columns }) => {
      const rows: OverviewTableRow[] = data.map( d => {
        const match = d.scores.find(s => s.dimension === dimension)
        const market = marketsByIsoCode[d.market_name]
        const marketDisplayLabel = market ? (
          <MarketDisplayLabel
            iso_code={market.iso_code}
            name={market.name} />
        ) : d.market_name
        if( !match ){
          return {
            label: marketDisplayLabel,
            score: null,
            previous: null,
            value: null,
            delta: null,
            target: null,
          }
        }
        return {
          label: marketDisplayLabel,
          score: match.current,
          delta: typeof match.change !== 'undefined' ? match.change : comparisonFunction(match.current, match.previous),
          value: match.value,
          previous: match.previous,
          target: match.target,
        }
      })

      const tableGranularity = fixedGranularity || granularity

      const title = `${label} ${getDateLabel(tableGranularity, referenceDate)}`

      const deltaTooltipContent = getTooltipContent(tableGranularity, referenceDate)

      return {
        category,
        dimension,
        title,
        tooltipContent: (
          <>
            { tooltipContent && (
              <>
                { tooltipContent }
                <br />
                <br />
              </>
            )}
            { deltaTooltipContent }
          </>
        ),
        props: {
          rows,
          columns,
        }
      }
    }
  )
}


const displayNA = <Box color='text.disabled'>N / A</Box>

// Flag to enable/disable colorisation of score and change values
const COLORISE_VALUES = false

export const getTableRenderers = (
  appTheme: AppTheme,
  comparisonMethod: ComparisonMethod,
): CellRenderers<OverviewTableRow> => {
  return {
    // eslint-disable-next-line
    score: ({ score, target }) => {
      if( score === null ){
        return displayNA
      }
      let color = appTheme.palette.text.primary
      if( COLORISE_VALUES ){
        const targetDelta = getAbsoluteDelta(score, target)
        let health: 0 | 0.5 | 1 | 'null' = 'null'
        if( targetDelta !== null ){
          const absoluteDelta = Math.abs(targetDelta)
          health = (
            absoluteDelta > 5 ?
              0 :
              absoluteDelta > 2 ?
                0.5 :
                1
          )
        }
        const healthColorMap: Record<typeof health, string> = {
          null: appTheme.palette.text.primary,
          0: appTheme.palette.error.main,
          0.5: appTheme.palette.warning.main,
          1: appTheme.palette.success.main,
        }
        color = healthColorMap[health]
      }
      return <span style={{color}}>{percentageFormatter(score)}</span>
    },
    // eslint-disable-next-line
    target: ({ target }) => {
      if( target === null ){
        return displayNA
      }
      return <>{percentageFormatter(target)}</>
    },
    // eslint-disable-next-line
    delta: ({ delta, score, previous, target }) => {
      if( delta === null ){
        return displayNA
      }
      const targetDelta = getAbsoluteDelta(score, target)
      const previousDelta = getAbsoluteDelta(previous, target)

      // Colorise delta according to whether it has moved closer to or further away from target
      let color = appTheme.palette.text.primary
      if( COLORISE_VALUES ){
        color = appTheme.palette.text.disabled
        if( targetDelta !== null && previousDelta !== null ){
          const absoluteTargetDelta = Math.abs(targetDelta)
          const absolutePreviousDelta = Math.abs(previousDelta)
          color = (
            absoluteTargetDelta < absolutePreviousDelta ?
              appTheme.chart.healthColourScale(1) :
              absoluteTargetDelta > absolutePreviousDelta ?
                appTheme.chart.healthColourScale(0) :
                appTheme.palette.text.disabled
          )
        }
      }

      const formatter = comparisonMethod === 'ABSOLUTE' ? percentagePointDeltaFormatter : percentageDeltaFormatter

      return <span style={{color}}>{formatter(delta)}</span>
    },
    // eslint-disable-next-line
    value: ({ value }) => {
      if( value === null || value === undefined ){
        return displayNA
      }
      return <Money abbreviate={false} amount={value} currency='EUR' />
    }
  }
}
