import { QuadrantType } from '@percept/types'

import { PositionalAttributes, SizeAttributes } from './types'


export const quadrantHealth: Record<QuadrantType, number> = {
  top_left: 0.5,
  top_right: 1,
  bottom_left: 0,
  bottom_right: 0.25,
}

export const quadrantContrastTextFill: Record<QuadrantType, string> = {
  top_left: '#000',
  top_right: '#FFF',
  bottom_left: '#FFF',
  bottom_right: '#FFF',
}

export const scaleRectangle = ({
  width,
  height,
  scaleFactor,
}: SizeAttributes & {
  scaleFactor: number
}): SizeAttributes => {
  const totalArea = width * height
  const scaledArea = totalArea * scaleFactor
  const x = scaledArea / totalArea
  const factor = Math.sqrt(x)
  const scaledWidth = width * factor
  const scaledHeight = height * factor
  return {
    width: scaledWidth,
    height: scaledHeight,
  }
}


export const getQuadrantDatumAttributes = ({
  quadrant,
  value,
  width,
  height,
  x,
  y,
  midPointX,
  midPointY,
  offset = 0,
}: {
  quadrant: QuadrantType
  value: number
  surfaceSize?: number
  midPointX: number
  midPointY: number
  offset?: number
} & SizeAttributes & PositionalAttributes): PositionalAttributes & SizeAttributes => {
  const scaleFactor = value / 100
  switch(quadrant){
    case 'top_left': {
      const attrs = scaleRectangle({
        width: midPointX,
        height: midPointY,
        scaleFactor,
      })
      return {
        width: attrs.width - offset,
        height: attrs.height - offset,
        x: x + midPointX - attrs.width,
        y: y + midPointY - attrs.height,
      }

    }
    case 'bottom_left': {
      const attrs = scaleRectangle({
        width: midPointX,
        height: height - midPointY,
        scaleFactor,
      })
      return {
        width: attrs.width - offset,
        height: attrs.height - offset,
        x: x + midPointX - attrs.width,
        y: y + midPointY + offset,
      }
    }
    case 'top_right': {
      const attrs = scaleRectangle({
        width: width - midPointX,
        height: midPointY,
        scaleFactor,
      })
      return {
        width: attrs.width - offset,
        height: attrs.height - offset,
        x: x + midPointX + offset,
        y: y + midPointY - attrs.height,
      }
    }
    case 'bottom_right': {
      const attrs = scaleRectangle({
        width: width - midPointX,
        height: height - midPointY,
        scaleFactor,
      })
      return {
        width: attrs.width - offset,
        height: attrs.height - offset,
        x: x + midPointX + offset,
        y: y + midPointY + offset,
      }
    }
  }
}
