import React from 'react'

import { AppTheme, AppThemeOptions, createAppTheme } from './themeAugmentation'

import { JellyfishLogo, Percept } from '../components/Logos'

import { basePerceptThemeOptions } from './basePerceptThemeOptions'

import {
  baseDarkPerceptThemeOptions,
  baseLightPerceptThemeOptions,
} from './perceptAppThemeV2'

import { merge } from 'lodash-es'

import { AppBranding, ThemeType } from '@percept/types'


type BrandedAppThemeOptions = (
  Pick<
    AppThemeOptions, 'branding'
  > &
  Partial<
    RecursiveOptional<
      Omit<
        AppThemeOptions, 'branding'
      >
    >
  >
)


type RecursiveOptional<T extends object> = {
  [K in keyof T]: (T[K] extends object ? (Partial<T[K]> | undefined) : T[K] | undefined)
}


export const makeBrandedAppTheme = (
  options: BrandedAppThemeOptions,
  themeOptions?: Omit<AppThemeOptions, 'branding'>
): AppTheme => (
  createAppTheme(merge({}, basePerceptThemeOptions, themeOptions || {}, options))
)


const brandingConfigs: Record<'PERCEPT' | 'BRANDTECH', BrandedAppThemeOptions> = {
  PERCEPT: {
    branding: {
      label: 'Percept',
      LogoComponent: Percept,
    }
  },
  BRANDTECH: {
    branding: {
      label: 'Brandtech Group',
      // eslint-disable-next-line
      LogoComponent: (props) => (
        <JellyfishLogo
          {...props}
          size={20}
          units='px' />
      ),
    },
  },
}


export const brandedTypedThemes: (
  Record<'PERCEPT', Record<ThemeType, AppTheme>> &
  Partial<Record<AppBranding, Record<ThemeType, AppTheme>>>
) = {
  PERCEPT: {
    DARK: makeBrandedAppTheme(brandingConfigs.PERCEPT, baseDarkPerceptThemeOptions),
    LIGHT: makeBrandedAppTheme(brandingConfigs.PERCEPT, baseLightPerceptThemeOptions),
  },
  BRANDTECH: {
    DARK: makeBrandedAppTheme(brandingConfigs.BRANDTECH, baseDarkPerceptThemeOptions),
    LIGHT: makeBrandedAppTheme(brandingConfigs.BRANDTECH, baseLightPerceptThemeOptions),
  }
}
