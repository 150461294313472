import React from 'react'

import { Column, CellRenderers, formatMoney, Loader, Typography, Box } from '@percept/mui'

import { ChartData, MultiDataset, percentageDeltaFormatter, percentageFormatter } from '@percept/mui/charts'

import { CreativeWastageResponse, WastageComparisonType, WastageSegmentation, WastageVariant } from '@percept/hooks'

import { capitalize, get, sum, uniq } from 'lodash-es'

import { ChartDataWithBaseline, datasetConfigurations } from '../dataUtils'

import { ComparisonMethod, getPercentage, getPercentageDelta } from '@percept/utils'

import { getDatasetTotal } from '@percept/mui/charts/lib'

import { produceKeyedMapping } from 'components/Overview/dataUtils'

import { AllDatasets } from '../typings'


export type WastageTrendTableProps = {
  variant: WastageVariant
  segmentation: WastageSegmentation
  comparisonType: WastageComparisonType | null
  comparisonMethod?: ComparisonMethod
  supportsComparison: boolean
  isLoadingComparisons?: boolean
  spendDatasets: MultiDataset[]
  wastagePercentageDataset: ChartData | null
  creativeQualityScoreDataset: ChartData | null
  spendComparisonDatasets: MultiDataset[]
  wastagePercentageComparisonDataset: ChartData | null
  creativeQualityScoreComparisonDataset: ChartData | null
  totalCreativeWastageResponse: CreativeWastageResponse | undefined
  totalCreativeWastageComparisonResponse: CreativeWastageResponse | undefined
}


type ComparisonDatasets = (
  | 'efficientSpendComparison'
  | 'wastedSpendComparison'
  | 'wastagePercentageComparison'
  | 'creativeQualityScoreComparison'
)

export type ColumnKey = AllDatasets | ComparisonDatasets | 'label'

const columnOrder: ColumnKey[] = [
  'label',
  'wastedSpend',
  'wastedSpendComparison',
  'efficientSpend',
  'efficientSpendComparison',
  'wastagePercentage',
  'wastagePercentageComparison',
  'creativeQualityScore',
  'creativeQualityScoreComparison',
]

export type WastageTrendRow = Record<AllDatasets | ComparisonDatasets, number | null> & {
  label: string
}

export const getColumns = ({
  wastageVariant,
  wastageSegmentation,
  comparisonType,
  comparisonMethod,
}: {
  wastageVariant: WastageVariant
  wastageSegmentation: WastageSegmentation
  comparisonType: WastageComparisonType | null
  comparisonMethod: ComparisonMethod
}): Column<WastageTrendRow>[] => {
  const variantLabel = (
    wastageVariant === 'COMBINED' ?
      'Total' :
      capitalize(wastageVariant)
  )
  const comparisonSuffix = comparisonMethod === 'RELATIVE' ? '%' : 'pp'
  let comparisonDisplayLabel = ''
  switch(comparisonType){
    case 'YEAR':
      comparisonDisplayLabel = `YoY ${comparisonSuffix}`
      break
    case 'MONTH':
      comparisonDisplayLabel = `MoM ${comparisonSuffix}`
      break
    case 'QUARTER':
      comparisonDisplayLabel = `QoQ ${comparisonSuffix}`
      break
  }
  return columnOrder.reduce( (acc, key) => {
    // Hide comparison columns when not available
    if( !comparisonType && key.includes('Comparison') ){
      return acc
    }
    let label: string | null = comparisonDisplayLabel
    const config = datasetConfigurations[key as AllDatasets]
    if( config ){
      label = config.displayLabel
      if( key.includes('wast') ){
        label = `${variantLabel} ${label}`
      }
    }
    if( key === 'label' ){
      label = null
    }
    acc.push({ key, label, align: key === 'label' ? 'left' : 'right' })
    return acc
  }, [] as Column<WastageTrendRow>[])
}

const displayNull = (
  <Box color='text.disabled'>
    N / A
  </Box>
)

function makeCellRenderer<T extends object>(
  formatter: (value: any) => any,
  handleNull = true
): ((key: keyof T) => (item: T) => any) {
  return (key) => (item): any => {
    if( handleNull && item[key] === null ){
      return displayNull
    }
    return formatter(item[key])
  }
}

const makePercentageRenderer = makeCellRenderer<WastageTrendRow>(
  percentageFormatter
)

const makePercentageDeltaRenderer = makeCellRenderer<WastageTrendRow>(
  percentageDeltaFormatter
)

const makeCurrencyRenderer = makeCellRenderer<WastageTrendRow>(
  amount => formatMoney({
    currency: 'EUR',
    amount,
    abbreviate: false,
  })
)

const makeLoaderRenderer = makeCellRenderer<WastageTrendRow>(
  () => <Loader size='1em' color='inherit' preset='inline' />,
  false
)

const percentageKeys: ColumnKey[] = [
  'creativeQualityScore',
  'wastagePercentage',
]

const percentageDeltaKeys: ColumnKey[] = [
  'creativeQualityScoreComparison',
  'efficientSpendComparison',
  'wastagePercentageComparison',
  'wastedSpendComparison',
]

const currencyKeys: ColumnKey[] = [
  'efficientSpend',
  'wastedSpend',
]

function makeCellRenderers<T extends object>(
  keys: (Extract<keyof T, string>)[],
  getRenderer: (key: keyof T) => (item: T) => JSX.Element | null
): CellRenderers<T> {
  return keys.reduce((acc, key) => {
    acc[key] = getRenderer(key)
    return acc
  }, {} as CellRenderers<T>)
}

export const renderers: CellRenderers<WastageTrendRow> = {
  ...makeCellRenderers(percentageKeys, makePercentageRenderer),
  ...makeCellRenderers(percentageDeltaKeys, makePercentageDeltaRenderer),
  ...makeCellRenderers(currencyKeys, makeCurrencyRenderer),
}

export const comparisonLoadingRenderers: CellRenderers<WastageTrendRow> = {
  ...renderers,
  ...makeCellRenderers(percentageDeltaKeys, makeLoaderRenderer),
}


const getBaselineTotal = (data: ChartDataWithBaseline): number => (
  sum(
    data.map( d => d.baselineValue || 0 )
  )
)


export const getWastageTrendRows = ({
  spendDatasets,
  wastagePercentageDataset,
  creativeQualityScoreDataset,
  totalCreativeWastageResponse,
  totalCreativeWastageComparisonResponse,
  comparisons,
}: Pick<
  WastageTrendTableProps,
  'spendDatasets' | 'creativeQualityScoreDataset' | 'wastagePercentageDataset' |
  'totalCreativeWastageResponse' | 'totalCreativeWastageComparisonResponse'
> & {
  comparisons: Record<AllDatasets, ChartDataWithBaseline | null> | null
}): { rows: WastageTrendRow[], totalRow: WastageTrendRow | null } => {
  const efficientSpend = spendDatasets.find( d => d.key === 'efficientSpend' )
  const wastedSpend = spendDatasets.find( d => d.key === 'wastedSpend' )

  if( !(efficientSpend && wastedSpend && creativeQualityScoreDataset && wastagePercentageDataset) ){
    return { rows: [], totalRow: null }
  }

  const efficientSpendMap = produceKeyedMapping(
    efficientSpend && efficientSpend.data, 'label'
  )
  const wastedSpendMap = produceKeyedMapping(
    wastedSpend && wastedSpend.data, 'label'
  )
  const creativeQualityScoreMap = produceKeyedMapping(
    creativeQualityScoreDataset, 'label'
  )
  const wastagePercentageMap = produceKeyedMapping(
    wastagePercentageDataset, 'label'
  )

  const efficientSpendComparisonMap = produceKeyedMapping(
    comparisons && comparisons.efficientSpend, 'label'
  )
  const wastedSpendComparisonMap = produceKeyedMapping(
    comparisons && comparisons.wastedSpend, 'label'
  )
  const creativeQualityScoreComparisonMap = produceKeyedMapping(
    comparisons && comparisons.creativeQualityScore, 'label'
  )
  const wastagePercentageComparisonMap = produceKeyedMapping(
    comparisons && comparisons.wastagePercentage, 'label'
  )

  const mergedLabels = uniq([
    ...Object.keys(efficientSpendMap),
    ...Object.keys(wastedSpendMap),
    ...Object.keys(creativeQualityScoreMap),
    ...Object.keys(wastagePercentageMap),
  ])

  const rows: WastageTrendRow[] = mergedLabels.map( label => {
    return {
      label,
      efficientSpend: get(efficientSpendMap, [label, 'value'], null),
      efficientSpendComparison: get(efficientSpendComparisonMap, [label, 'value'], null),
      wastedSpend: get(wastedSpendMap, [label, 'value'], null),
      wastedSpendComparison: get(wastedSpendComparisonMap, [label, 'value'], null),
      creativeQualityScore: get(creativeQualityScoreMap, [label, 'value'], null),
      creativeQualityScoreComparison: get(creativeQualityScoreComparisonMap, [label, 'value'], null),
      wastagePercentage: get(wastagePercentageMap, [label, 'value'], null),
      wastagePercentageComparison: get(wastagePercentageComparisonMap, [label, 'value'], null),
    }
  })

  const totalEfficientSpend = getDatasetTotal(efficientSpend.data)
  const totalWastedSpend = getDatasetTotal(wastedSpend.data)
  const totalWastagePercentage = getPercentage(totalWastedSpend, totalWastedSpend + totalEfficientSpend)

  const baselineTotalEfficientSpend = comparisons && comparisons.efficientSpend && getBaselineTotal(
    comparisons.efficientSpend
  )
  const baselineTotalWastedSpend = comparisons && comparisons.wastedSpend && getBaselineTotal(
    comparisons.wastedSpend
  )

  const totalEfficientSpendComparison = totalEfficientSpend && baselineTotalEfficientSpend && (
    getPercentageDelta(
      totalEfficientSpend, baselineTotalEfficientSpend
    )
  )
  const totalWastedSpendComparison = totalWastedSpend && baselineTotalWastedSpend && (
    getPercentageDelta(
      totalWastedSpend, baselineTotalWastedSpend
    )
  )
  const totalWastagePercentageComparison = getPercentageDelta(
    totalWastagePercentage,
    (
      baselineTotalEfficientSpend && baselineTotalWastedSpend ?
        getPercentage(baselineTotalWastedSpend, baselineTotalWastedSpend + baselineTotalEfficientSpend) :
        null
    )
  )
  const totalCreativeQualityScore = get(totalCreativeWastageResponse, 'creative_quality_score', null)
  const totalCreativeQualityScoreComparison = getPercentageDelta(
    totalCreativeQualityScore,
    get(totalCreativeWastageComparisonResponse, 'creative_quality_score', null)
  )

  const totalRow: WastageTrendRow = {
    label: 'Total',
    efficientSpend: totalEfficientSpend,
    efficientSpendComparison: totalEfficientSpendComparison,
    wastedSpend: totalWastedSpend,
    wastedSpendComparison: totalWastedSpendComparison,
    wastagePercentage: totalWastagePercentage,
    wastagePercentageComparison: totalWastagePercentageComparison,
    creativeQualityScore: totalCreativeQualityScore === null ? null : totalCreativeQualityScore * 100,
    creativeQualityScoreComparison: totalCreativeQualityScoreComparison,
  }

  return { rows, totalRow }
}
