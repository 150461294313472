import { ComparisonMethod, aggregatePotentialEfficiencies, getComparisonFunction } from '@percept/utils'

import { CreativeWastageResponse, CreativeWastageResponseWithMarket, OrgUnitProviderPotentialEfficiencyScores } from '@percept/hooks'

import { get, mapValues } from 'lodash-es'

import { vodafoneMarkets } from 'vodafoneMarkets'

import { DateRange, PerformanceValue, PotentialEfficiencyDatum } from '@percept/types'

import {
  CreativeXDimension,
  DerivedCreativeXDatasetMapping,
  DerivedPotentialEfficiency,
  DerivedPotentialEfficiencyMapping,
  MarketDatum,
  MarketDatumWithoutGranularity,
  OverviewDimension,
  OverviewGranularity,
} from './typings'


export const produceKeyedMapping = <T extends object>(
  items: T[] | null | undefined,
  key: Extract<keyof T, string>
): Record<string, T> => {
  if( !items ){
    return {}
  }
  return items.reduce( (acc, item) => {
    acc[item[key] as string] = item
    return acc
  }, {} as Record<string, T>)
}

export const staticDimensions: OverviewDimension[] = [
  'share_of_spend', 'brand_investment_share', 'production_vs_media'
]

export const creativeXDimensions: CreativeXDimension[] = [
  'creative_quality_score', 'creative_wastage'
]

export const isCreativeXDimension = (overviewDimension: OverviewDimension): overviewDimension is CreativeXDimension => (
  creativeXDimensions.includes(overviewDimension as CreativeXDimension)
)


const getDatumFromComparisonDatasets = ({
  key,
  dimension,
  primary,
  comparison,
  // secondary,
  // secondaryComparison,
  target = null,
  comparisonMethod = 'ABSOLUTE',
}: {
  primary: CreativeWastageResponse | null
  comparison: CreativeWastageResponse | null
  secondary?: CreativeWastageResponse | null
  secondaryComparison?: CreativeWastageResponse | null
  key: keyof CreativeWastageResponse
  target?: PerformanceValue
  comparisonMethod?: ComparisonMethod
  dimension: OverviewDimension
}): MarketDatumWithoutGranularity => {
  let current = primary ? primary[key] : null, previous = comparison ? comparison[key] : null
  if( key === 'creative_quality_score' ){
    if( current !== null ){
      current *= 100
    }
    if( previous !== null ){
      previous *= 100
    }
    if( target !== null ){
      target *= 100
    }
  }
  // NOTE - we coerce null percentages to 0 here
  if( key === 'creative_wastage_percentage' ){
    current = current || 0
  }
  const comparisonFunction = getComparisonFunction(comparisonMethod)
  return {
    dimension,
    current,
    target,
    previous,
    change: comparisonFunction(current, previous),
  }
}


export const getDerivedCreativeXDatasetMapping = (
  props: {
    primary: CreativeWastageResponse | null
    comparison: CreativeWastageResponse | null
    comparisonMethod: ComparisonMethod
    secondary?: CreativeWastageResponse | null
    secondaryComparison?: CreativeWastageResponse | null
  }
): DerivedCreativeXDatasetMapping => {
  const creative_quality_score: MarketDatumWithoutGranularity = getDatumFromComparisonDatasets({
    key: 'creative_quality_score',
    dimension: 'creative_quality_score',
    target: 0.8,
    ...props,
  })

  const creative_wastage = {
    ...getDatumFromComparisonDatasets({
      key: 'creative_wastage_percentage',
      dimension: 'creative_wastage',
      ...props,
    }),
    value: get(props.primary, 'creative_wastage_converted_cost', null),
  }

  return { creative_quality_score, creative_wastage }
}


export type CreativeXDatasetsByMarket = Record<string, Record<CreativeXDimension, MarketDatum>>


export const resolveCreativeXDatasetsByMarket = ({
  granularity,
  primary,
  comparison,
  comparisonMethod,
  // secondary,
  // secondaryComparison,
}: {
  granularity: OverviewGranularity
  primary: CreativeWastageResponseWithMarket[]
  comparison: CreativeWastageResponseWithMarket[]
  comparisonMethod: ComparisonMethod
  secondary?: CreativeWastageResponseWithMarket[]
  secondaryComparison?: CreativeWastageResponseWithMarket[]
}): CreativeXDatasetsByMarket => {
  const primaryByMarket = produceKeyedMapping(primary, 'market')
  const comparisonByMarket = produceKeyedMapping(comparison, 'market')
  // const secondaryByMarket = produceKeyedMapping(secondary, 'market')
  // const secondaryComparisonByMarket = produceKeyedMapping(secondaryComparison, 'market')

  const overviewDatasetMapping: CreativeXDatasetsByMarket = {}

  vodafoneMarkets.forEach(({ creativeXMarket, iso_code }) => {
    const { creative_quality_score, creative_wastage } = getDerivedCreativeXDatasetMapping({
      primary: primaryByMarket[creativeXMarket] || null,
      comparison: comparisonByMarket[creativeXMarket] || null,
      comparisonMethod,
      // secondary: secondaryByMarket[creativeXMarket],
      // secondaryComparison: secondaryComparisonByMarket[creativeXMarket],
    })
    overviewDatasetMapping[iso_code] = {
      creative_quality_score: {...creative_quality_score, granularity },
      creative_wastage: {...creative_wastage, granularity },
    }
  })

  return overviewDatasetMapping
}


export const getDerivedPotentialEfficiencyDatum = ({
  primary,
  comparison,
  comparisonMethod = 'ABSOLUTE',
}: {
  primary: DerivedPotentialEfficiency | null
  comparison: DerivedPotentialEfficiency | null
  comparisonMethod?: ComparisonMethod
}): MarketDatumWithoutGranularity => {
  const current = get(primary, 'potential_efficiency_ratio', null)
  const previous = get(comparison, 'potential_efficiency_ratio', null)
  const comparisonFunction = getComparisonFunction(comparisonMethod)
  return {
    dimension: 'digital_wastage',
    current: current === null ? null : current * 100,
    previous: previous === null ? null : previous * 100,
    value: get(primary, 'transformed_cost', null),
    change: comparisonFunction(current, previous),
    target: null,
  }
}


const vodafoneMarketsById = produceKeyedMapping(vodafoneMarkets, 'id')


export const getDerivedPotentialEfficienciesByIsoCode = (
  orgUnitProviderPotentialEfficiencies: OrgUnitProviderPotentialEfficiencyScores[],
  dateRange?: DateRange | null,
): DerivedPotentialEfficiencyMapping => {
  const efficienciesByIsoCode = orgUnitProviderPotentialEfficiencies.reduce( (acc, d) => {
    // NOTE - we may see org units which we don't have matched to markets here
    const matchedMarket = vodafoneMarketsById[d.org_unit_id]
    if( !matchedMarket ){
      return acc
    }
    const { iso_code } = matchedMarket
    acc[iso_code] = acc[iso_code] || []
    acc[iso_code] = acc[iso_code].concat(d.potential_efficiency_scores)
    return acc
  }, {} as Record<string, PotentialEfficiencyDatum[]>)

  return mapValues(efficienciesByIsoCode, v => aggregatePotentialEfficiencies(v, dateRange))
}


export const getDerivedTotalWastageDatum = ({
  primaryCreativeWastage,
  primaryPotentialEfficiency,
  secondaryCreativeWastage,
  secondaryPotentialEfficiency,
  comparisonMethod = 'ABSOLUTE',
}: {
  primaryCreativeWastage: CreativeWastageResponse | null
  primaryPotentialEfficiency: DerivedPotentialEfficiency | null
  secondaryCreativeWastage: CreativeWastageResponse | null
  secondaryPotentialEfficiency: DerivedPotentialEfficiency | null
  comparisonMethod?: ComparisonMethod
}): MarketDatumWithoutGranularity => {
  const primaryValue = (
    Number(get(primaryCreativeWastage, 'creative_wastage_converted_cost') || 0)
    + Number(get(primaryPotentialEfficiency, 'transformed_cost') || 0)
  )
  const secondaryValue = (
    Number(get(secondaryCreativeWastage, 'creative_wastage_converted_cost') || 0)
    + Number(get(secondaryPotentialEfficiency, 'transformed_cost') || 0)
  )
  const currentTotalCost = Number(get(primaryPotentialEfficiency, 'total_cost') || 0)
  const current = (
    currentTotalCost === 0 || primaryValue === 0 ?
      null :
      (primaryValue / currentTotalCost) * 100
  )
  const previousTotalCost = Number(get(secondaryPotentialEfficiency, 'total_cost') || 0)
  const previous = (
    previousTotalCost === 0 || secondaryValue === 0 ?
      null :
      (secondaryValue / previousTotalCost) * 100
  )
  const comparisonFunction = getComparisonFunction(comparisonMethod)
  return {
    dimension: 'total_wastage',
    current,
    previous,
    change: comparisonFunction(current, previous),
    value: primaryValue,
    target: null,
  }
}
